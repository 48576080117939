import React from "react"
import ImageMeta from "../components/ImageMeta"
import Button from "../components/Buttons/Button"

import Layout from "../components/layout"
import SEO from "../components/seo"

import tree from "../images/404tree.svg"

import {
  Image,
  Video,
  Transformation,
  CloudinaryContext,
} from "cloudinary-react"

import { Link } from 'gatsby';

const customNavLink = {
  title: "Enroll Now",
  href: "/contact-us/#form",
  type: "anchor",

}

const NotFoundPage = () => (
  
    <Layout 
      language="en"
      customNavButton={customNavLink}
      footerType="minimal"
    >
      <SEO
        title="404 Error | SIXSTAR"
        description="404 page not found."
        robots="noindex"
      />

      <div className="section nav-spacer">

        <div className="columns is-vcentered page-not-found">
          <div className="column is-3"></div>
          <div className="column is-8">
            <ImageMeta
              publicId={"SIX/DEV/mark"}
              cloudName="nuvolum"
              width="auto"
              responsive
              responsiveUseBreakpoints="true"
              className="mark"
            />
          </div>
          <div className="column is-1"></div>
          <div className="column">
            <h1>404: Page not found</h1>
            <h2>Looks like this page is lost in the stars.</h2>
            <p>It looks like the page you are looking for is missing or no longer exists. Please check the URL to make sure it’s correct, or go back to the homepage.</p>
            <Button
              href="/"
              buttonText="Home"
              internal
            />
          </div>
          <div className="column is-3"></div>
        </div>

      </div>

    </Layout>
  
)

export default NotFoundPage
